import { render, staticRenderFns } from "./editvalidasi.vue?vue&type=template&id=c4ea1afe"
import script from "./editvalidasi.vue?vue&type=script&lang=js"
export * from "./editvalidasi.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports