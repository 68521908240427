<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CCard>
          <CCardHeader>
            <strong>DATA VALIDASI AKHIR </strong><small> Detail Data</small>
          </CCardHeader>
          <CCardBody>
            <CForm @submit.prevent="updateValidasi">
              <CRow>
              <CCol sm="6">
                <CInput
                  v-model="kode_skpd"
                  label="Kode SKPD :"
                  readonly
                  placeholder="-"
                />
              </CCol>
              <CCol sm="6">
                <CInput
                  v-model="nama_skpd"
                  label="Nama SKPD :"
                  readonly
                  placeholder="-"
                />
              </CCol>
              <CCol sm="6">
                <CInput
                  v-model="kabupaten_kota"
                  label="Kota/Kabupaten :"
                  readonly
                  placeholder="-"
                />
              </CCol>
              <CCol sm="6">
                <CInput
                  v-model="kecamatan"
                  label="Kecamatan :"
                  readonly
                  placeholder="-"
                />
              </CCol>
              <CCol sm="6">
                <CInput
                  v-model="kelurahan"
                  label="Kelurahan :"
                  readonly
                  placeholder="-"
                />
              </CCol>
              <CCol sm="6">
                <CInput
                v-model="kode_lokasi"
                label="Kode Lokasi :"
                readonly
                placeholder="-"
              />
              </CCol>              
              <CCol sm="6">
                <CInput
                  v-model="nama_instansi"
                  label="Nama OPD :"
                  readonly
                  placeholder="-"
                />
              </CCol>
              <CCol sm="6">
                <CInput
                  v-model="kode_barang"
                  label="Kode Barang :"
                  readonly
                  placeholder="-"
                />
              </CCol>
              <CCol sm="6">
                <CInput
                  v-model="nama_barang"
                  label="Nama Barang :"
                  readonly
                  placeholder="-"
                />
              </CCol>
              <CCol sm="6">
                <CInput
                  v-model="letak_lokasi"
                  label="Letak/Alamat :"
                  readonly
                  placeholder="-"
                />
              </CCol>
              <CCol sm="6">
                <CInput
                  v-model="no_hak"
                  label="Nomor HAK :"
                  readonly
                  placeholder="-"
                />
              </CCol>
              <CCol sm="6">
                <CInput
                  v-model="jenis_hak"
                  label="Tipe Hak :"
                  readonly
                  placeholder="-"
                />
              </CCol>
              <CCol sm="6">
                <CInput
                  v-model="tgl_perolehan"
                  label="Tanggal Perolehan :"
                  readonly
                  placeholder="-"
                />
              </CCol>
              <CCol sm="6">
                <CInput
                  v-model="luas_tertulis"
                  label="Luas (m2) :"
                  readonly
                  placeholder="-"
                />
              </CCol>
              <CCol sm="6">
                <CInput
                  v-model="tgl_sertifikat"
                  label="Tanggal Sertifikat :"
                  readonly
                  placeholder="-"
                />
              </CCol>
              <CCol sm="6">
                <CInput
                  v-model="nilai_perolehan"
                  label="Harga Perolehan :"
                  readonly
                  placeholder="-"
                />
              </CCol>
              
              <CCol sm="3">
                <div class="form-group">
                  <label>Patok Batas :</label>
                  <select v-model="status_patok" placeholder="Pilihan" class="form-control" readonly>
                    <option value="" disabled selected>-- Pilihan --</option>
                    <option value="1" disabled>Ada</option>
                    <option value="0" disabled>Tidak Ada</option>
                  </select>
                </div>
              </CCol>
              <CCol sm="3">
                <div class="form-group">
                  <label>Pagar :</label>
                  <select v-model="status_pagar" placeholder="Pilihan" class="form-control" readonly>
                    <option value="" disabled selected>-- Pilihan --</option>
                    <option value="1" disabled>Ada</option>
                    <option value="0" disabled>Tidak Ada</option>
                  </select>
                </div>
              </CCol>
              <CCol sm="3">
                <div class="form-group">
                  <label>Plang Tanah :</label>
                  <select v-model="status_plang" placeholder="Pilihan" class="form-control" readonly>
                    <option value="" disabled selected>-- Pilihan --</option>
                    <option value="1" disabled>Ada</option>
                    <option value="0" disabled>Tidak Ada</option>
                  </select>
                </div>
              </CCol>
              <CCol sm="3">
                <div class="form-group">
                  <label>Bangunan :</label>
                  <select v-model="status_bangunan" placeholder="Pilihan" class="form-control" readonly>
                    <option value="" disabled selected>-- Pilihan --</option>
                    <option value="1" disabled>Ada</option>
                    <option value="0" disabled>Tidak Ada</option>
                  </select>
                </div>
              </CCol>              
              <CCol sm="12">
                <CTextarea
                  v-model="temuan_masalah"
                  label="Temuan Permasalahan :"
                    readonly
                    placeholder="-"
                />
              </CCol>
              <CCol sm="12">
                <CTextarea
                  v-model="ket_tambahan"
                  label="Keterangan Tambahan :"
                    readonly
                    placeholder="-"
                />
              </CCol>
              <CCol sm="6">
                <div class="form-group">
                  <label>Status Verifikasi Lapangan :</label>
                  <select v-model="status_verlap" placeholder="Pilihan" class="form-control" readonly>
                    <option value="" disabled selected>-- Pilihan --</option>
                    <option value="1" disabled>Sudah Verifikasi Lapangan</option>
                    <option value="0" disabled>Belum Verifikasi Lapangan</option>
                  </select>
                </div>
              </CCol>
              <CCol sm="6">
                <div class="form-group">
                  <label>Status Validasi Akhir :</label>
                  <select v-model="validasi" placeholder="Pilihan" class="form-control">
                    <option value="" disabled selected>-- Pilihan --</option>
                    <option value="1">Sudah Validasi Akhir</option>
                    <option value="0">Belum Validasi Akhir</option>
                  </select>
                </div>
              </CCol>
            </CRow><br>
            
            <CRow>
              <CCol sm="4">
                <div class="form-group" style="height: 200px;width: 100%;" >
                  <img :src="foto_lokasi" class="img-fluid" style="height: 100%;width: 100%;" />
                </div>
              </CCol>
              <CCol sm="4">
                <div class="form-group" style="height: 200px;width: 100%;" >
                  <img :src="foto_patok" class="img-fluid" style="height: 100%;width: 100%;" />
                </div>
              </CCol>
              <CCol sm="4">
                <div class="form-group" style="height: 200px;width: 100%;" >
                  <img :src="foto_pagar" class="img-fluid" style="height: 100%;width: 100%;" />
                </div>
              </CCol>
              <CCol sm="4">
                <div class="form-group" style="height: 200px;width: 100%;" >
                  <img :src="foto_plang" class="img-fluid" style="height: 100%;width: 100%;" />
                </div>
              </CCol>
              <CCol sm="4">
                <div class="form-group" style="height: 200px;width: 100%;" >
                  <img :src="foto_bangunan" class="img-fluid" style="height: 100%;width: 100%;" />
                </div>
              </CCol>
              <CCol sm="12"><hr>
                <div style="height: 400px;width: 100%;" >
                <l-map ref="mapElement" :zoom="zoom" :center="center" style='height: 100%;width: 100%;'>
                  <l-tile-layer :url="url"></l-tile-layer>
                  <l-geo-json ref="track" :geojson="geojsonFile"  ></l-geo-json>
                  <!-- <l-polygon :lat-lngs="latlngs" color="red"></l-polygon> -->
                </l-map>
                </div><hr>
              </CCol>          

            </CRow>           
              <div class="form-actions">
                <CButton type="submit" color="primary" style='margin-right:15px'>Simpan</CButton>
                <CButton color="secondary" to="/validasi" style='margin-right:15px'>Kembali</CButton>
                <!-- <CButton type="" color="warning" style='margin-right:15px'>Excel</CButton>
                <CButton type="" color="danger" style='margin-right:15px'>PDF</CButton> -->
              </div>
            </CForm>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <CModal
      title="Error Input"
      color="danger"
      id="modalError"
    >
      <b>Error</b>! Data gagal diinput.
    </CModal>
   
  </div>  
  
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import axios from 'axios';
import * as Modals from '../notifications/Modals.vue'
// import { CurrencyInput } from 'vue-currency-input'

export default {
  components: {Datepicker},
  name: 'Forms',
  computed : {
    years () {
      const year = new Date().getFullYear()
      return Array.from({length: year - 2019}, (value, index) => new Date().getFullYear() - index)
    }
  },
  data () {
    return {
      kode_skpd : "",
      nama_skpd : "",
      kabupaten_kota : "",
      kode_lokasi : "",
      nama_instansi : "",
      letak_lokasi : "",
      kode_barang : "",
      nama_barang : "",
      no_hak : "",
      jenis_hak : "",
      luas_tertulis : "",
      tgl_sertifikat : "",
      kecamatan : "",
      kelurahan : "",
      nilai_perolehan : "",
      tgl_perolehan : "",

      status_patok : "",
      status_pagar : "",
      status_plang : "",
      status_bangunan : "",
      temuan_masalah : "",
      ket_tambahan : "",
      status_verlap : "",
      validasi : "",
      foto_lokasi : "",

      foto_patok : "",
      foto_patok_text : "",
      foto_pagar : "",
      foto_pagar_text : "",
      foto_plang : "",
      foto_plang_text : "",
      foto_bangunan : "",
      foto_bangunan_text : "",
      placeholder1 : "- - Pilih File - -",
      placeholder2 : "- - Pilih File - -",
      placeholder3 : "- - Pilih File - -",
      placeholder4 : "- - Pilih File - -",
      
      selected: [], // Must be an array reference!
      show: true,
      horizontal: { label:'col-3', input:'col-9' },
      options: ['Option 1', 'Option 2', 'Option 3'],
      jenis: ['Penyelenggaran Jalan Kabupaten/Kota'],
      subjenis: ['Pembangunan dan Peningkatan Jalan', 'Rekonstruksi / Pemeliharaan / Rehabilitasi Jalan', 'Pembangunan Jembatan / Box Culvert'],
      tahuns: ['2021','2020'],
      selectOptions: [
        'Option 1', 'Option 2', 'Option 3',
        { 
          value: 'some value', 
          label: 'Selected option'
        }
      ],
      selectedOption: 'some value',

      formCollapsed: true,
      checkboxNames: ['Checkboxes', 'Inline Checkboxes',
                      'Checkboxes - custom', 'Inline Checkboxes - custom'],
      radioNames: ['Radios', 'Inline Radios',
                    'Radios - custom', 'Inline Radios - custom']
    }
  },
  mounted(){
  this.showValidasi();
  // this.loadData();
  },

  methods: {
    onFileChange1(event) {
      var fileData = event.target.files[0];
      this.foto_patok_text = fileData.name;
      this.foto_patok = fileData;
      this.placeholder = fileData.name;
    },
    onFileChange2(event) {
      var fileData = event.target.files[0];
      this.foto_pagar_text = fileData.name;
      this.foto_pagar = fileData;
      this.placeholder2 = fileData.name;
    },
    onFileChange3(event) {
      var fileData = event.target.files[0];
      this.foto_plang_text = fileData.name;
      this.foto_plang = fileData;
      this.placeholder3 = fileData.name;
    },
    onFileChange4(event) {
      var fileData = event.target.files[0];
      this.foto_bangunan_text = fileData.name;
      this.foto_bangunan = fileData;
      this.placeholder4 = fileData.name;
    },
    updateValidasi: function(event){
      // Simple POST request with a JSON body using axios
      const validasi = { 
                          validasi:this.validasi,
                          id_verlap: this.$route.params.id_verlap
                        };  
      // alert(JSON.stringify(verlap));
      axios.post(process.env.VUE_APP_BASE_URL+"updatevalidasi", validasi)
        .then((response) => {
            // console.log(response);
            // alert(response.data);
            if(response.status==200){
              this.$router.push('/validasi');
            }
            else{
              this.myError = true;
            }
            
        })
    },
    showValidasi: function(event){
      // Simple POST request with a JSON body using axios
      const validasi = { 
                          kode_skpd:this.kode_skpd,
                          nama_skpd:this.nama_skpd,
                          kabupaten_kota:this.kabupaten_kota,
                          kecamatan:this.kecamatan,
                          kelurahan: this.kelurahan, 
                          kode_lokasi: this.kode_lokasi, 
                          nama_instansi: this.nama_instansi, 
                          letak_lokasi: this.letak_lokasi, 
                          kode_barang: this.kode_barang, 
                          nama_barang: this.nama_barang, 
                          no_hak: this.no_hak, 
                          jenis_hak: this.jenis_hak, 
                          tgl_perolehan: this.tgl_perolehan, 
                          nilai_perolehan: this.nilai_perolehan, 
                          tgl_sertifikat: this.tgl_sertifikat, 
                          luas_tertulis: this.luas_tertulis, 

                          foto_lokasi: this.foto_lokasi, 
                          status_patok:this.status_patok,
                          status_pagar:this.status_pagar,
                          status_plang:this.status_plang,
                          status_bangunan:this.status_bangunan,
                          temuan_masalah:this.temuan_masalah,
                          ket_tambahan:this.ket_tambahan,
                          luas:this.luas,
                          nilai_znt:this.nilai_znt,
                          zona_rdtr:this.zona_rdtr,
                          status_verlap:this.status_verlap,
                          validasi : this.validasi
                        };
      // alert(JSON.stringify(pekerjaan));
      // alert(this.$route.params.id_User);
      let username =window.btoa(this.$route.params.id_user);
      console.log(username);

      axios.get(process.env.VUE_APP_BASE_URL+"editvalidasi/"+this.$route.params.id_verlap)
        .then((response) => {
              // alert(JSON.stringify(response));            
              this.kode_skpd=response.data.kode_skpd;
              this.nama_skpd=response.data.nama_skpd;
              this.kabupaten_kota=response.data.kabupaten_kota;
              this.kecamatan=response.data.kecamatan;
              this.kelurahan=response.data.kelurahan;
              this.kode_lokasi=response.data.kode_lokasi;
              this.nama_instansi=response.data.nama_instansi;
              this.letak_lokasi=response.data.letak_lokasi;
              this.kode_barang=response.data.kode_barang;
              this.nama_barang=response.data.nama_barang;
              this.no_hak=response.data.no_hak;
              this.jenis_hak=response.data.jenis_hak;
              this.tgl_perolehan=response.data.tgl_perolehan;
              this.nilai_perolehan=response.data.nilai_perolehan;
              this.tgl_sertifikat=response.data.tgl_sertifikat;
              this.luas_tertulis=response.data.luas_tertulis;

              this.foto_lokasi=response.data.foto_lokasi;
              this.status_patok=response.data.status_patok;
              this.status_pagar=response.data.status_pagar;
              this.status_plang=response.data.status_plang;
              this.status_bangunan=response.data.status_bangunan;
              this.temuan_masalah=response.data.temuan_masalah;
              this.ket_tambahan=response.data.ket_tambahan;
              this.status_verlap=response.data.status_verlap;

              this.luas=response.data.luas;
              this.nilai_znt=response.data.nilai_znt;
              this.zona_rdtr=response.data.zona_rdtr;
              this.validasi=response.data.validasi;
              // this.placeholder=response.data.file;
              
        })
    }
  }
}
</script>